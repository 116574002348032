import React, { createElement, Fragment, useMemo } from 'react';
import unified from 'unified';
import markdown from 'remark-parse';
import remark2rehype from 'remark-rehype';
import rehype2react from 'rehype-react';
import raw from 'rehype-raw';
import breaks from 'remark-breaks';
import slug from 'remark-slug';
import removeEmptyParagraphs from 'remark-squeeze-paragraphs';
import {
  Box,
  Text,
  Img,
  OrderedList,
  UnorderedList,
  ListItem,
  Container,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Code,
  Divider,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useLocation } from '@reach/router';

import Heading from '../heading';
import CanonicalLink from '../canonical-link';
import LazyIframe from '../lazy-iframe';

const component = (Component, defaultProps) => ({ ...props }) => (
  <Component {...defaultProps} {...props} />
);

const calculateImgSrc = (src, imageWidth, origin) => {
  try {
    if (
      src.includes('images.contentful.com') ||
      src.includes('images.ctfassets.net')
    ) {
      const url = new URL(
        src.replace('images.contentful.com', 'images.ctfassets.net'),
        origin.replace('http://', 'https://'),
      );
      url.searchParams.append('fm', 'webp');
      url.searchParams.append('w', imageWidth || '500');
      url.searchParams.append('q', '90');
      return url.href;
    }
    return src;
  } catch {
    return src;
  }
};

const markdownContentStyles = {
  '.float-left': {
    float: 'left',
  },
  'img.float-left': {
    margin: 4,
    marginTop: 0,
    marginLeft: 0,
  },
  '.float-right': {
    float: 'right',
  },
  'img.float-right': {
    margin: 4,
    marginTop: 0,
    marginRight: 0,
  },
  '.mx-auto': {
    marginX: 'auto',
    marginY: 4,
  },
  'p > a': {
    wordBreak: 'break-word',
  },
  'p > p': {
    display: 'inline',
  },
  'p:nth-of-type(even) img': {
    float: ['none', 'none', 'left'],
    maxWidth: ['75%', '50%'],
    marginX: ['auto', 'auto', 4],
    marginY: 4,
    marginLeft: [undefined, undefined, 0],
  },
  'p:nth-of-type(odd) img': {
    float: ['none', 'none', 'right'],
    maxWidth: ['75%', '50%'],
    marginX: ['auto', 'auto', 4],
    marginY: 4,
    marginRight: [undefined, undefined, 0],
  },
  '.text-center iframe, .text-center .iframe-placeholder': {
    marginY: 2,
    marginX: 'auto',
  },
};

const getHeadingLevel = (headingNum, headerLevelStart = 0) =>
  `h${headingNum + headerLevelStart}`;

export const reactComponents = (headerLevelStart) => ({
  h1: component(Heading, { as: getHeadingLevel(1, headerLevelStart), mb: 4 }),
  h2: component(Heading, { as: getHeadingLevel(2, headerLevelStart), mb: 3 }),
  h3: component(Heading, { as: getHeadingLevel(3, headerLevelStart), mb: 2 }),
  h4: component(Heading, { as: getHeadingLevel(4, headerLevelStart), mb: 2 }),
  h5: component(Heading, { as: getHeadingLevel(5, headerLevelStart), mb: 1 }),
  h6: component(Heading, { as: getHeadingLevel(6, headerLevelStart), mb: 1 }),
  p: component(Text, {
    my: 2,
  }),
  img: ({ ...props }) => {
    const { origin } = useLocation();
    const imageWidth = useBreakpointValue(['330', '360', '500']);
    return component(Img, {
      maxWidth: '100%',
      maxHeight: '400px',
    })({ ...props, src: calculateImgSrc(props.src, imageWidth, origin) });
  },
  a: ({ href, ...props }) => (
    <CanonicalLink
      wordBreak="break-word"
      textDecoration="underline"
      to={href}
      {...props}
    />
  ),
  ol: component(OrderedList),
  ul: component(UnorderedList),
  li: component(ListItem),
  strong: component(Text, { as: 'strong' }),
  i: component(Text, { as: 'i' }),
  u: component(Text, { as: 'u' }),
  abbr: component(Text, { as: 'abbr' }),
  cite: component(Text, { as: 'cite' }),
  del: component(Text, { as: 'del' }),
  em: component(Text, { as: 'em' }),
  ins: component(Text, { as: 'ins' }),
  kbd: component(Text, { as: 'kbd' }),
  mark: component(Text, { as: 'mark' }),
  code: component(Code),
  s: component(Text, { as: 's' }),
  q: component(Text, { as: 'cite' }),
  samp: component(Text, { as: 'samp' }),
  sub: component(Text, { as: 'sub' }),
  sup: component(Text, { as: 'sup' }),
  hr: component(Divider),
  figure: component(Container, {
    as: 'figure',
    maxW: 'container.md',
    my: 4,
    sx: {
      '& > img': { margin: '0 auto' },
    },
  }),
  figcaption: component(Text, {
    as: 'figcaption',
    fontWeight: 'bold',
    align: 'center',
  }),
  table: component(Table, { as: 'table', my: 8 }),
  thead: component(Thead, { as: 'thead' }),
  tbody: component(Tbody, { as: 'tbody' }),
  tfoot: component(Tfoot, { as: 'tfoot' }),
  tr: component(Tr, { as: 'tr' }),
  th: component(Th, { as: 'th' }),
  td: component(Td, { as: 'td' }),
  caption: component(TableCaption, { as: 'caption' }),
  iframe: (props) => <LazyIframe {...props} />,
});

const createMarkdownProcessor = (headerLevelStart) =>
  unified()
    .use(markdown)
    .use(breaks)
    .use(removeEmptyParagraphs)
    .use(slug)
    .use(remark2rehype, { allowDangerousHtml: true })
    .use(raw)
    .use(rehype2react, {
      sanitize: false,
      createElement,
      Fragment,
      components: reactComponents(headerLevelStart),
    });

const EmptyMarkdown = () => (
  <Box
    dangerouslySetInnerHTML={{
      __html: '<!–– no content ––>',
    }}
  />
);

const MarkdownRemarkHtml = ({ source, headerLevelStart = 1, ...props }) => {
  const markdownProcessor = useMemo(
    () => createMarkdownProcessor(headerLevelStart),
    [headerLevelStart],
  );
  const { result: html } = markdownProcessor.processSync(source);
  return (
    <Box sx={markdownContentStyles} {...props}>
      {html}
    </Box>
  );
};

const Markdown = ({ source, ...props }) => {
  const markdownSource = source?.internal?.content || source;
  if (!markdownSource) {
    return <EmptyMarkdown />;
  }
  return <MarkdownRemarkHtml source={markdownSource} {...props} />;
};

export default Markdown;
