import React from 'react';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { Box } from '@chakra-ui/react';

import { reactComponents } from '../markdown/markdown';

// seehttps://github.com/contentful/rich-text/blob/master/packages/rich-text-react-renderer/src/index.tsx#L5
const richTextOptions = {
  renderMark: {
    [MARKS.BOLD]: reactComponents.strong,
    [MARKS.ITALIC]: reactComponents.i,
    [MARKS.UNDERLINE]: reactComponents.u,
    [MARKS.CODE]: reactComponents.code,
  },
  renderNode: {
    [BLOCKS.DOCUMENT]: (node, children) => children,
    [BLOCKS.PARAGRAPH]: (node, children) => reactComponents(1).p({ children }),
    [BLOCKS.HEADING_1]: (node, children) => reactComponents(1).h1({ children }),
    [BLOCKS.HEADING_2]: (node, children) => reactComponents(1).h2({ children }),
    [BLOCKS.HEADING_3]: (node, children) => reactComponents(1).h3({ children }),
    [BLOCKS.HEADING_4]: (node, children) => reactComponents(1).h4({ children }),
    [BLOCKS.HEADING_5]: (node, children) => reactComponents(1).h5({ children }),
    [BLOCKS.HEADING_6]: (node, children) => reactComponents(1).h6({ children }),
    [BLOCKS.UL_LIST]: (node, children) => reactComponents(1).ul({ children }),
    [BLOCKS.OL_LIST]: (node, children) => reactComponents(1).ol({ children }),
    [BLOCKS.LIST_ITEM]: (node, children) => reactComponents(1).li({ children }),
    [BLOCKS.QUOTE]: (node, children) => reactComponents(1).quote({ children }),
    [BLOCKS.HR]: () => reactComponents(1).hr(),
    [BLOCKS.EMBEDDED_ENTRY]: (node, children) => <Box>{children}</Box>,
    [INLINES.HYPERLINK]: (node, children) =>
      reactComponents(1).a({ href: node.data.uri, children }),
    // [INLINES.EMBEDDED_ENTRY]: ...
    // [INLINES.ENTRY_HYPERLINK]: ...
    // [INLINES.ASSET_HYPERLINK]: ...
  },
};

const RichText = ({ content, ...options }) => (
  <>
    {renderRichText(content, {
      ...richTextOptions,
      ...options,
    })}
  </>
);

export default RichText;
